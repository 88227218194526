import { NavLink } from 'react-router-dom';
import React from 'react';
import logo from '../images/fcp.png';
import HClogo from '../images/HCReport_logo.jpg';

const Index = () => (
    <div className="container">
    <div style={{color: '#565347', textAlign: 'center'}}>
    <img alt="HClogo" src={HClogo}/>

      <h4>Improving People Management Practices</h4>
      <a href="https://www.HumanCapital.Report" target="_blank"><h4>www.HumanCapital.Report</h4></a>
      <h4>Fargus Consulting Practice</h4>
      <div> The Fargus Consulting Practice (FCP) is a family business. We specialise in measuring and improving motivation in business. </div>
    </div>
    </div>
);

export default Index;
